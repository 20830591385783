<script>
import Layout from "../../../layouts/main";
import { Helper } from "../../../../helper.js";
import Config from "../../../../config.js";
import axios from "axios";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";

export default {
  mixins: [Helper],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      loading: false,
      loading_submit: false,
      input_name: "",
      val_input_name: false,
      input_des: "",
      val_input_des: false,
      file1: null,
      userStart: JSON.parse(localStorage.getItem("user")),
      data_url: Config,
      items: [
        {
          text: "Helpdesk",
        },
        {
          text: "View",
          active: true,
        },
      ],
      ordersData: [],
      domain:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "number", sortable: true, label: "Ticket Number" },
        { key: "name", sortable: true, label: "Issue Title" },
        { key: "status", sortable: true, label: "Status" },
        { key: "date", sortable: true, label: "Date" },
        { key: "detail", sortable: true, label: "Detail" },
      ],
    };
  },
  computed: {
    rows() {
      return this.ordersData.length;
    },
  },
  mounted() {
    this.ApiCallData();
    this.totalRows = this.ordersData.length;
    this.domain = process.env.VUE_APP_AUTHDOMAIN;
  },
  methods: {
    reset() {
      this.file1 = null;
      this.input_name = "";
      this.input_des = "";
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async submit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-success ml-2",
        },
        buttonsStyling: false,
      });

      this.val_input_name = false;
      this.val_input_des = false;
     
      if (this.file1 != null) {
        
        if(this.vadFile(this.file1.type) == false){
            alert("File Type is Not Allowed");
            this.file1 = null;
            return false;
        }

        if (this.file1.size > 1000 * 1000) {
          alert("File size " + this.file1.size + " more than 1 MB");
          this.file1 = null;
          return false;
        }
      }

      if (this.input_name == "") {
        this.val_input_name = true;
      } else if (this.input_des == "") {
        this.val_input_des = true;
      } else {
        let dataJson = {
          name: this.input_name,
          description: this.input_des,
        };

        let rawData = JSON.stringify(dataJson);
        let formData = new FormData();
        formData.append("file", this.file1);
        formData.append("json", rawData);

        this.loading_submit = true;
        await axios
          .post(
            process.env.VUE_APP_AUTHDOMAIN +
              this.data_url.creteHelpdeskTicketMultipartFormType,
            formData,
            {
              headers: {
                "Forca-Token": this.userStart.token,
                // "Accept": "application/json",
                "Content-Type": "'multipart/form-data", //"application/x-www-form-urlencoded" // "text/html"
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.codestatus == "S") {
              swalWithBootstrapButtons.fire(
                "Ticket Submitted !",
                "Thank you, your ticket is being processed",
                "success"
              );

              this.ApiCallData();
              this.reset();
            } else {
              this.wrongTokenCo(res);
            }
          })
          .catch((error) => {
            if (this.is_runTest == false) {
              console.log(error);
            }

            try {
              if (error.response.status === 401) {
                this.callLogOut();
              }
            } catch (error) {
              if (this.is_runTest == false) {
                console.log(error);
              }
            }
          });
        this.loading_submit = false;
      }
    },
    dataSplit(number, data, split) {
      let d = data.split(split);
      return d[number];
    },

    async ApiCallData() {
      this.loading = true;

      let formData = new URLSearchParams();
      formData.append("perpage", 9999);
      formData.append("page", 1);

      console.log(this.userStart);
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getHelpdeskTicket,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              // "Accept": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.codestatus == "S") {
            this.ordersData = res.data.resultdata;
            this.totalRows = this.ordersData.length;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });

      this.loading = false;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="'Helpdesk'" :items="items" />
    <div class="row">
      <div class="col-lg-9">
        <div class="card ml-2">
          <div class="card-body pt-0">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
                
              <b-table
                class="table-centered"
                :items="ordersData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >

                <template v-slot:cell(status)="data">
                    <div style="font-weight:bold;">
                        <span class="badge text-white" style="background-color:#69b984; font-size:14px " v-if="data.item.stage.stage_name == 'New'" v-html="data.item.stage.stage_name"></span>
                        <span class="badge text-white text-weight-bold" style="background-color:#fed049; font-size:14px !important;" v-if="data.item.stage.stage_name == 'In Progress'" v-html="data.item.stage.stage_name"></span>
                        <span class="badge text-white" style="background-color:#40E0D0; font-size:14px !important;" v-if="data.item.stage.stage_name == 'Awaiting'" v-html="data.item.stage.stage_name"></span>
                        <span class="badge text-white" style="background-color:#3e5857; font-size:14px !important;" v-if="data.item.stage.stage_name == 'Done'" v-html="data.item.stage.stage_name"></span>
                        <span class="badge text-white" style="background-color:#737373; font-size:14px !important;" v-if="data.item.stage.stage_name == 'Cancelled'" v-html="data.item.stage.stage_name"></span>
                    </div>
                </template>

                <template v-slot:cell(date)="data">
                  <div>
                    {{ dataSplit(0, data.item.date, " ") }}
                  </div>
                </template>

                <template #row-details="data">
                      <b-card>
                        <div class="row">
                            <div class="col-8">
                                <span style="font-weight:bold;text-decoration: underline;" >Description <br/><br/></span>
                                  <div v-html="data.item.description"></div>
                            </div>
                            <div class="col-4">
                                 <span style="font-weight:bold;text-decoration: underline;" >Attachment <br/><br/></span>
                                <a
                                    v-if="data.item.attachment.length > 0"
                                    :href="data.item.attachment[0].document_link"
                                    >Download</a
                                >
                                <a v-if="data.item.attachment.length == 0">No attachment</a>
                            </div>
                        </div>
                      </b-card>
                </template>

                <template v-slot:cell(detail)="data">
                    <div>
                        <a
                        @click="data.toggleDetails"
                        class="mr-3 text-primary ml-3"
                        style="cursor:pointer;"
                        v-b-tooltip.hover
                        title="View Detail"
                      >
                        <i
                          class="far fa-eye font-size-18"
                          style="margin-top: -5px"
                        ></i>
                        </a>
                    </div>
                </template>
              </b-table>


            </div>
            <b-spinner variant="primary" label="Spinning" v-if="loading"></b-spinner>
            <div class="row mb-5">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card mr-2">
          <div class="card-body pt-3">
            <h5 class="card-title">New Ticket</h5>
            <b-alert :show="val_input_des || val_input_name" variant="danger"
              >Please, Fill The Required Field</b-alert
            >
            <b-form-group
              label="Issue Title *"
              label-for="input-formatter"
              class="mt-4"
            >
              <b-form-input value="" v-model="input_name"></b-form-input>
            </b-form-group>

            <b-form-group label="Description *" label-for="input-formatter">
              <b-form-textarea
                id="textarea"
                v-model="input_des"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <a :href="domain + '/get/sample/import-contact'" target="blank"><b>Download Template Master Data</b></a>
            <b-form-group label="Attachment" label-for="input-formatter">
              * Maximum file size is 1 MB <br />
              * Allowed file types : XLSX, XLS
              <b-form-file
                v-model="file1"
                class="mt-3"
                accept=".xls,.xlsx"
                plain
              ></b-form-file>
            </b-form-group>

            <b-button
              :disabled="loading_submit"
              class="float-right mt-3"
              type="submit"
              variant="primary"
              @click="submit"
              >{{ loading_submit ? "Loading" : "Submit" }}</b-button
            >

            <b-button
              class="float-left mt-3"
              type="button"
              variant="danger"
              @click="reset"
              >Reset</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
